import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";
import Image from "gatsby-image";
import LangSupport from '../components/langSupport/lang-support';
import ApiBlock from '../components/apiBlock/api-block';
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import WidgetContainer from "../components/WidgetContainer/WidgetContainer";
import Category from "../components/SideNav/Category";
import { trimMonthInDateString } from "../../utils/clientSideHelpers";
import SearchHeader from "../components/searchHeader/searchHeader";
import Modal from "../components/commonComponents/modal/modal";
import Search from "../components/search/search";
import "./post.css";
import hljs from 'highlight.js/lib/core'
import 'highlight.js/styles/default.css'
import AnchorImage from "../images/anchor.svg";

const BlogPost = ({ data }) => {
  const { post, selectedVersion, selectedCategory } = data;
  const selectedVersionId = selectedVersion?.id;
  const title = post.title;
  const subTitle = post.subtitleAcf?.text;
  const description = post.postBrief?.brief ;
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  const [apiJSON, setApiJSON] = useState(null);
  const [endPoint, setEndPoint] = useState('https://mercury-uat.phonepe.com');
  const [docType, setDocType] = useState('guide')
  const [openSearch, setOpenSearch] = useState(false)
  const [content, setContent] = useState(post.content? post.content :'')
  const [versionData, setVersionData] = useState({});
  const [categroyData, setCatgoryData] = useState({});
  const [normalizedCat, setNormalizedCat] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  // merge the categories and posts
  useEffect(() => {
    const categories = data.categoriesData?.nodes;
    const posts = data.postsData?.nodes;

    const categoryObj = {};
    categories.forEach((category) => {
      categoryObj[category.id] = category;
      categoryObj[category.id]["posts"] = {"nodes": []};
    })

    posts.forEach((post) => {
      if (post?.categories?.nodes[0]?.id) {
        const catId = post?.categories?.nodes[0]?.id;
        if (catId in categoryObj) {
          categoryObj[catId].posts.nodes.push({
            id: post?.id,
            uri: post?.uri,
            slug: post?.slug,
            title: post?.title,
            excerpt: post?.excerpt,
            postOrder: post?.postOrder,
          })
        }
      }
    })

    const categoriesList = [];
    for (let catId in categoryObj) {
      categoriesList.push(categoryObj[catId])
    }

    setCategoriesData([...categoriesList]);

  }, []);

  useEffect(() => {
    if (post.content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString( hideUselessString( post.content ), 'text/html');
      if (doc) {
        getContent(doc);
    
        const data = doc.getElementById('unq-api-data');
        const endPointUrl = doc.getElementById('unq-endpoint-url');
        if (endPointUrl) {
          setEndPoint(endPointUrl.innerHTML);
        }
        if (data) {
          let contentData = data.innerHTML;
          contentData = contentData.replaceAll('”', '"');
          contentData = contentData.replaceAll('“', '"');
          contentData = contentData.replaceAll('″', '"');
          contentData = contentData.replaceAll('‘', '"');
          contentData = contentData.replaceAll('’', '"');
          let apiData = null;
          try {
            apiData = JSON.parse(contentData);
          } catch (error) { console.log(error); }
          setApiJSON(apiData);
        }
      }
    }
  }, [data])



  useEffect(() => {
    hljs.highlightAll()
    const copyElements = document.querySelectorAll('.dev-doc-copy');
    copyElements.forEach((ele) => {
      ele.addEventListener('click', copyCodeContent)
    })
    const hyperlinkButtons = document.querySelectorAll('.hyperlink-anchor');
    hyperlinkButtons.forEach((ele) => {
      ele.addEventListener('click', copyURL)
    })
  }, [content])

  
  useEffect(() => {
    let hash = window.location.hash;

    if (hash) {
      hash = hash.slice(1);
      const targetElement = document.getElementById(hash);
      if (targetElement) {
        // Not used scrollIntoView as need to make extra offset changes
        // targetElement.scrollIntoView({ behavior: 'smooth', block: "center"}); 

        const extraOffset = 100;
        const elementRect = targetElement.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.scrollY;
        const scrollToPosition = absoluteElementTop - extraOffset;

        window.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [content])

  const hideUselessString = ( 
    content
   ) => {
    let tempContent = content;
    if(tempContent){
      tempContent = tempContent.replaceAll('<p>&lt;html-block html=&quot;</p>', '');
      tempContent = tempContent.replaceAll('<p>&lt;html-block html=&#8221;</p>', '');
      tempContent = tempContent.replaceAll('<p>&#8220;&gt;</p>', '');
      tempContent = tempContent.replaceAll('<pre>', `<pre><div class="dev-doc-copy" ></div>`);
    }
    return tempContent;
  }

  const getApiHeader = () => {
    if (apiJSON) {
      return (
        <div className="api-header-text"> <span className="method-box">{apiJSON.method}</span>  {endPoint}{apiJSON.url}</div>
      )
    }
    return null;
  }

  const copyCodeContent = (event) => {
    if (event?.target?.nextSibling?.textContent) {
      navigator.clipboard.writeText(event.target.nextSibling.textContent)
    }
  }

  const copyURL = (event) => {
    let currLocation = window.location.href;
    let cleanCurrLocation = currLocation.split('#')[0];
    if (event?.target?.previousElementSibling) {
      let addLocation = event?.target?.previousElementSibling?.innerHTML;
      addLocation = '#' + addLocation.replace(/\s+/g, '-');
      cleanCurrLocation += addLocation;
      navigator.clipboard.writeText(cleanCurrLocation);

      // var myDiv = document.querySelectorAll("copied-container");
      var myDiv = event?.target?.nextElementSibling;
      myDiv.style.display = "block";

      setTimeout(() => {
        myDiv.style.display = "none";
      }, 3000);
    }
  }

  const getHyperLinkContent = (doc) => {
    const headingElements = doc.querySelectorAll("h2");
    headingElements.forEach((heading) => {

      const newDivElement = document.createElement('div')
      newDivElement.classList.add("heading-container");

      const newHeading = document.createElement('h2');
      newHeading.classList.add('hyperlink-heading');
      newHeading.innerHTML = heading.innerHTML;
      let newId = heading.innerHTML;
      newId = newId.replace(/\s+/g, '-');
      newHeading.id = newId;

      // add event listener on useEffect for button
      const anchorImg = document.createElement('img');
      anchorImg.src = AnchorImage;
      anchorImg.alt = 'anchor image';
      anchorImg.classList.add('hyperlink-anchor');

      const copiedDiv = document.createElement('div');
      copiedDiv.innerHTML = "Copied !"
      copiedDiv.classList.add('copied-container');
      

      newDivElement.appendChild(newHeading);
      newDivElement.appendChild(anchorImg);
      newDivElement.appendChild(copiedDiv);

      heading.parentNode.replaceChild(newDivElement, heading)
    })
    setContent(doc.body.innerHTML)
  }

  const getContent = (doc) => {
    const preElements = doc.querySelectorAll("pre code");
    preElements.forEach((ele)=>{
      const parentEle = ele.parentNode;
      const titleEle = document.createElement('div');
      titleEle.classList.add('code-snipet-header');
      if(ele.id){
        titleEle.innerHTML=ele.id;
      }
      parentEle.prepend(titleEle);
    })
    getHyperLinkContent(doc)
  }

  const getSearchDataList = () => {
    const searchDataList = [];
    const catgryData = categoriesData;
    const catgryObj = {};
    if (catgryData) {
      catgryData.forEach((ctgry)=>{
        catgryObj[ctgry.id]=ctgry;
      })
      catgryData.forEach((ctgry) => {
        if(ctgry.parentId===selectedVersionId || catgryObj[ctgry.parentId]?.parentId===selectedVersionId){
          if (ctgry?.posts?.nodes) {
            ctgry.posts.nodes.forEach((post) => {
              const dataObj = {};
              dataObj.title = post.title;
              dataObj.description = createExcerpt(post.postBrief?.brief,100);
              dataObj.uri = createPostUri(ctgry,post);
              searchDataList.push(dataObj)
            })
          }
        }
      })
    }
    return { searchDataArrayObject: { group: [...searchDataList] } };
  }

  const createPostUri = (category, post) => {
    let parentUri = category.uri.slice(0, -1);
    let docType = "guide";
    if (category.wpParent?.node) {
      if (category.wpParent.node.ancestors?.nodes?.length) {
        parentUri = category.wpParent.node.ancestors.nodes[0].uri;
        if(category.wpParent.node.description){
          docType = category.wpParent.node.description;
        }
      }
      else {
        parentUri = category.wpParent.node.uri;
        if (category.description) {
          docType = category.description;
        }
      }
    }
    parentUri = parentUri.replace('/category', '');
    if(parentUri==='/v5/'){
      parentUri='/'
    }
    parentUri = parentUri + (docType === 'guide' ? 'docs' : 'reference');
    return post.uri === '/gatsby-landing-page/' ? '/' : parentUri + post.uri;
  }

  function createExcerpt(content, maxLength) {
    if(!content)return '';
    // Remove HTML tags from content
    const strippedContent = content.replace(/(<([^>]+)>)/gi, '');
    // Truncate the content to the maxLength parameter
    if (strippedContent.length > maxLength) {
      return strippedContent.slice(0, maxLength) + '...';
    }
    return strippedContent;
  }



  return (
    <Layout isHomePage>
      <Seo title={title} image={featuredImage.fluid?.src} pageURI={post.uri} description={description}  />
      <SearchHeader 
        docType={docType} 
        selectedVersionId={selectedVersionId}
        setDocType={setDocType} 
        setOpenSearch={setOpenSearch} 
        versionData={versionData}
        categroyData={categroyData}
        normalizedCat={normalizedCat}
      />
      <WidgetContainer>
        <div className="blog-post">
          <Modal
            isOpen={openSearch}
            setIsOpen={setOpenSearch}
          >
            <Search pageContext={getSearchDataList()} />
          </Modal>
          <div className="side-bar">
            <Category
              docType={docType}
              setDocType={setDocType}
              data={categoriesData}
              selectedVersionId={selectedVersionId}
              selectedCategory={selectedCategory}
              versionData={versionData}
              setVersionData={setVersionData}
              categroyData={categroyData}
              setCatgoryData={setCatgoryData}
              normalizedCat={normalizedCat}
              setNormalizedCat={setNormalizedCat}
            />
          </div>
          <div className="main-content">
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h1>
                  <span itemProp="headline">{parse(title)}</span>
                  {getApiHeader()}
                </h1>

                {post?.postBrief?.brief&&<div className="post-brief" >{parse(post.postBrief.brief)}</div>}
                {featuredImage?.fluid && (
                  <Image
                    fluid={featuredImage.fluid}
                    alt={featuredImage.alt}
                    style={{ marginBottom: 50 }}
                  />
                )}
              </header>
              <section itemProp="articleBody">
                {parse(content)}
              </section>
              {apiJSON !== null && <ApiBlock apiData={apiJSON} setApiJSON={setApiJSON} />}
              <h2 className="subtitle footer-date">
                {subTitle && <small>{subTitle} </small>}
                <small className="doc-date">Updated on {trimMonthInDateString(post.date)}</small>
              </h2>
            </article>
          </div>
          {apiJSON !== null && <div className="api-box">
            <LangSupport end_point={endPoint} apiData={apiJSON} />
          </div>}
        </div>
      </WidgetContainer>
    </Layout>
  )
}

export default BlogPost

// (filter: {ancestors: {nodes: {elemMatch: {id: {eq: $versionId}}}}})
export const pageQuery = graphql`
  query PhonepeDocById2(
    $id: String!
    $versionId:String
    $parentCategoryId:String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      uri
      postBrief {
        brief
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    categoriesData: allWpCategory {
      nodes {
        id
        name
        count
        uri
        parentId
        description
        categoryOrder {
          acfOrder
          fieldGroupName
        }
        isvisible {
          visibility
        }
        wpChildren {
          nodes {
            id
            name
          }
        }
        wpParent{
          node {
            id
            name
            slug
            uri
            description
            ancestors {
              nodes {
                id
                name
                uri
                description
              }
            }
          }
        }
      }
    }

    postsData: allWpPost {
      nodes {
        id
        uri
        slug
        categories {
          nodes {
            id
          }
        }
        title
        excerpt
        postOrder {
          fieldGroupName
          order
        }
      }
    }

    selectedVersion: wpCategory(id: {eq:$versionId }){
      id
      name
      uri
    }

    selectedCategory: wpCategory(id: {eq:$parentCategoryId }){
      id
      name
      uri
      parentId
      description
    }

    recentPosts: allWpPost(sort: { order: DESC, fields: date }, limit: 5 ) {
      nodes {
        id
        title
        uri
      }
    }
  }
`
