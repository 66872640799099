import React, {
    useMemo,
    useState,
  } from "react";
  import parse from "html-react-parser";
  import PropTypes from "prop-types";
  import { QUERY_PARAMS } from "../../../utils/constants";
  import {
    LANGUAGES,
  } from "../../../utils/constants";
//   import { parserWrapper } from "../../../utils/nodeHelper";
  import Fuse from "fuse.js";
  import useDebounce from "../../../utils/useDebounce";
  import Spinner from "../../Spinner/spinner";
  import {
    getFromLocalStorage,
    setToLocalStorage,
  } from "../../../utils/helpers";
  import CustomLink from "../../commonComponents/CustomLink/customLink";
  import Type from "../../../images/typing.png"
  import './searchComponent.css'
//   import { sendClickEvent } from "../../../utils/garfieldHelpers";
  
  const RECENT_SEARCH = "recentSearches";
  
  // Initializing the client side search instance.
  // https://fusejs.io/api/options.html
  const initClientSideSearch = data => {
    if (!data) {
      return;
    }
    const options = {
      threshold: 0.0,
      ignoreLocation: true,
      minMatchCharLength: 3,
      includeMatches: true,
      // shouldSort: true,
      keys: ["title", "description"],
    };
  
    const search = new Fuse(data, options);
  
    return search;
  };
  
  // Register recent search to localstorage
  const registerToLocalStorage = (searchedLink, language) => {
    // Creating multiple keys to fetch and store recentSearches according to language
    const recentSearchKey = `${RECENT_SEARCH}-${language}`;
    const recentSearches =
      getFromLocalStorage(recentSearchKey, true) || [];
  
    const isSearchedLinkPresent = recentSearches?.findIndex(
      elem => elem?.uri === searchedLink?.uri
    );
  
    if (isSearchedLinkPresent !== -1) {
      recentSearches?.splice(isSearchedLinkPresent, 1);
    } else if (recentSearches?.length === 5) {
      recentSearches?.shift();
    }
    recentSearches?.push(searchedLink);
  
    setToLocalStorage(recentSearchKey, recentSearches, true);
  };
  
  // Creates a react element or a link which points to terminal page which has been searched.
  const getSearchedItem = (node, language) => {
    const {
      searchedValueStart,
      searchedValueEnd,
      item,
      highlight,
    } = node || {};
    const title = item?.title;
    const url = item.uri;
    const description=item.description;
    let formattedTitle = title;
  
    if (highlight && searchedValueStart >= 0) {
      formattedTitle = (
        <>
          {title?.substring(0, searchedValueStart)}
          <span className="highlight">
            {title?.substring(
              searchedValueStart,
              searchedValueEnd + 1
            )}
          </span>
          {title?.substring(searchedValueEnd + 1)}       
        </>
      );
    }
  
    return (
      <>
        <CustomLink
          to={url}
          key={item?.id}
          onClick={() => {
            // sendClickEvent(
            //   "SearchItemClick",
            //   item?.uri
            // );
            return registerToLocalStorage(
              {
                title,
                uri: item?.uri,
                id: item?.id,
              },
              language
            )
          }}
          overrideGeneralConfig={true}
        >
          <p className="search-item">
            {formattedTitle}
            <br/>
            <div className="description-text">{parse( description?.substring(0,150))}</div>
          </p>
        </CustomLink>
      </>
    );
  };
  
  //Returns a list of react components of search results which were the result of the search
  const getSearchedPages = (searchResult, language) => {
    const searchedPages = [];
    searchResult?.forEach(node => {
      const searchedItem = getSearchedItem(node, language);
      if (searchedItem) {
        searchedPages.push(searchedItem);
      }
    });
    return searchedPages;
  };
  
  // Creates react elements from the searches present in local storage
  const getRecentSearches = (language) => {
    const recentSearchKey = `${RECENT_SEARCH}-${language}`;
    const recentSearchesData =
      getFromLocalStorage(recentSearchKey, true) || [];
    return (
      <div className="type-something-cont" >
        <img src={Type} alt='type-something'></img>
        <div>Type something <br/> to search </div>
      </div>
    )
  };
  
  // check for exact string matching
  const isSearchValid = (node, searchValue) => {
    const { item, matches } = node || {};
    const title = item?.title;
    const ignoreValidityChecks = matches?.length > 1;
    const searchedTextIndex = title
      ?.toLowerCase()
      ?.indexOf(searchValue.toLowerCase());
  
    const searchedValueStart = searchedTextIndex;
    const searchedValueEnd =
      searchedTextIndex + searchValue?.length - 1;
    const highlight = searchedTextIndex !== -1;
    let isValid = true;
  
    if (
      !ignoreValidityChecks &&
      matches?.[0].key === "title"
    ) {
      isValid = searchedTextIndex !== -1;
    }
  
    return {
      isValid,
      searchedValueStart,
      searchedValueEnd,
      highlight,
    };
  };
  
  const SearchComponent = (
    {
    data: { searchDataArrayObject: { group = [] } = {} } = {},
  }
  ) => {
    const language = useMemo(
      () =>
        getFromLocalStorage(QUERY_PARAMS.LANGUAGE, false) ||
        LANGUAGES.EN,
      []
    );
    const fuseSearch = useMemo(
      () =>
        initClientSideSearch(
          group
        ),
      []
    );
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [search] = useState(fuseSearch);
    const [searchResult, setSearchResult] = useState([]);
    const [
      showNoResultError,
      setShowNoResultError,
    ] = useState(false);
  
    // Performs the search using the FuseJs instance.
    const performSearch = searchValue => {
      setIsLoading(true);
      const searchResult = search
        ? search.search(searchValue)
        : [];
  
      const possibleKeywords = searchValue
        ?.split(" ")
        ?.map(item =>
          item?.length >= 3 ? item?.trim() : undefined
        );
  
      for (let keyword of possibleKeywords) {
        if (keyword) {
          const keywordSearchResult = search?.search(keyword);
          searchResult?.push(...keywordSearchResult);
        }
      }
  
      const uniqueNode = {};
      const filteredSearchResult = [];
  
      for (let node of searchResult) {
        if (!uniqueNode[node?.item?.uri]) {
          uniqueNode[node?.item?.uri] = true;
          const {
            isValid,
            searchedValueStart,
            searchedValueEnd,
            highlight,
          } = isSearchValid(node, searchValue);
  
          if (isValid) {
            filteredSearchResult?.push({
              ...node,
              searchedValueStart,
              searchedValueEnd,
              matches: null,
              highlight,
            });
          }
        }
      }
  
      setShowNoResultError(
        filteredSearchResult?.length === 0
      );
      setSearchResult(filteredSearchResult);
      setIsLoading(false);
    };
  
    const debouncedSearch = useDebounce(performSearch);
  
    const handleInputChange = e => {
      const searchValue = e?.target?.value;
      setInput(searchValue);
      setSearchResult([]);
  
      if (searchValue?.length >= 3) {
        debouncedSearch(searchValue);
      }
    };
  
    console.log(searchResult)
    const searchedPages = getSearchedPages(
      searchResult,
      language
    );
  
    const isInputValid = input?.length > 2;
    return (
      <div className="search-container">
        <div className="input-container">
          <input
            autoFocus
            value={input}
            onChange={handleInputChange}
            placeholder="Search Help"
          />
          {/* {input.length ? (
            <div
              className="close-icon"
              onClick={() => {
                setInput("");
                setSearchResult([]);
                setShowNoResultError(false);
              }}
            >
             
            </div>
          ) : (
            ""
          )} */}
        </div>
  
        <div className="search-results-container">
          {isLoading ? (
            <div className="align-vert-center">
              <Spinner />
            </div>
          ) : (
            ""
          )}
          {!input?.length ? (
            <div className="recent-search-container">
              {getRecentSearches(language)}
            </div>
          ) : (
            ""
          )}
  
          {!isLoading &&
          !showNoResultError &&
          isInputValid &&
          searchedPages?.length ? (
            <>
              <p className="search-heading">
                Search Results({searchedPages?.length})
              </p>
              {searchedPages.map(item => item)}
            </>
          ) : (
            ""
          )}
  
          {isInputValid && !isLoading && showNoResultError ? (
            <div className="no-result">
              <p>No results found</p>{" "}
              <p>
                You can try searching for a different term or
                recheck for any spelling errors.
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  
  export default SearchComponent;
  
  SearchComponent.propTypes = {
    data: PropTypes.object,
  };
  