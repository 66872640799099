
const QUERY_PARAMS = {
  LANGUAGE: "language",
  TAG: "tag",
  CATEGORY: "category",
  PLATFORM: "platform",
  APP_THEME: "appTheme",
  NEW_USER: "newUser",
};

const isRunningOnBrowser = () =>
  typeof window !== "undefined";


const LANGUAGES = {
  EN: "EN",
};

module.exports = {
  QUERY_PARAMS,
  isRunningOnBrowser,
  LANGUAGES 
};
