import dropdown from "../images/down-arrow-svgrepo-com.svg";
import { isRunningOnBrowser } from "./constants";

const addSpan = className => {
  Array.from(
    document.getElementsByClassName(className)
  )?.forEach(element => {
    element.innerHTML = `<img src=${dropdown} />`;
  });
};

const checkFieldAvailabilityforPages = (
  pages,
  field,
  allPagesData
) => {
  for (let page of pages) {
    if (!allPagesData?.[page]?.[field]) {
      return false;
    }
  }
  return true;
};

const getFromLocalStorage = (key, shouldParse) => {
  if (isRunningOnBrowser()) {
    return shouldParse
      ? JSON.parse(window?.localStorage?.getItem(key))
      : window?.localStorage?.getItem(key);
  }
};

const setToLocalStorage = (
  key,
  value,
  shouldStringify = false
) => {
  if (isRunningOnBrowser()) {
    window?.localStorage?.setItem(
      key,
      shouldStringify ? JSON.stringify(value) : value
    );
  }
};

const extractLanguage = uri => uri?.slice(1, 3);

const redirectTo = url => {
  if (isRunningOnBrowser && url) {
    window.location.href = url;
  }
};

export {
  addSpan,
  checkFieldAvailabilityforPages,
  getFromLocalStorage,
  setToLocalStorage,
  extractLanguage,
  redirectTo,
};
