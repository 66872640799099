import React, { useState, useRef, useEffect } from "react";
import DownArrow from '../../../images/down-arrow-gray.svg';
import WhiteDownArrow from '../../../images/white-down-arrow.svg';
import GreenTick from '../../../images/green-tick.svg';
import './dropdown.css';

export default function Dropdown({ selectVal, label, options, customCssClass, handleChange, isArrowLight }) {
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClicks);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClicks);
        };

    }, [isOpen]);

    const handleOutsideClicks = (event) => {
        if (isOpen && menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        };
    };

    return (
        <div>
            <div className='dropdown-btn-cont' ref={menuRef} onClick={() => setIsOpen(!isOpen)}>
                <span className='lang-text'>{selectVal}&nbsp;<span><img src={isArrowLight? WhiteDownArrow: DownArrow} className="down-arrow-icon" alt='down arrow'></img></span> </span>
                {isOpen && <div className={`cs-dropdown ${customCssClass?.dropDownBox}`}>
                    <div>{label}</div>
                    {options.map((opt, index) => <div key={index} onClick={() => handleChange(opt)} className='cs-dropdownContent'><span>{opt}</span>{selectVal===opt&&<img src={GreenTick} alt='green-tick'></img>}</div>)}
                </div>}
            </div>
        </div>
    )
}