import React, { useState } from "react";
import Dropdown from '../commonComponents/dropDown/dropdown';
import Editor from "@monaco-editor/react";
import { textData, getTextData } from "./constants";
import CopyText from '../../images/copy-text-white.svg';
import '../../css/api-component.css';

export default function LangSupport({
    end_point,
    apiData
}) {
    const [lang, setLang] = useState('node');
    const [library, setLibrary] = useState('axios');
    const [apiResponse, setApiResponse] = useState(null);

    const handleLangChange = (val) => {
        setLang(val);
        setLibrary(textData?.[val]?.[0]);
    }

    const handleLibChange = (val) => {
        setLibrary(val);
    }

    const makeApiCall = () => {
        let headerValues = {};
        let bodyValues = {};
        if (apiData?.params) {
            const headersArr = apiData.params.filter((param) => param.in === 'header' && (param.default || param.customUserInput));
            const bodyArr = apiData.params.filter((param) => param.in === 'body' && (param.default || param.customUserInput));
            headersArr.forEach((header) => {
                headerValues[header.name] = (header.customUserInput || header.default);
            })
            bodyArr.forEach((header) => {
                bodyValues[header.name] = (header.customUserInput || header.default);
            })
        }
        const options = {
            method: apiData.method,
            headers: {
                ...headerValues,
            },

        };
        if (apiData.method !== 'get') {
            options['body'] = JSON.stringify(bodyValues);
        }
        let customUrl = apiData.url;
        apiData?.params?.forEach((param) => {
            if (param.in === 'path') {
                if(param.customUserInput || param.default)
                {
                    customUrl = customUrl.replaceAll(`{${param.name}}`, `${param.customUserInput || param.default}`)
                } 
            }
        })
        customUrl = customUrl.replaceAll('{','');
        customUrl = customUrl.replaceAll('}','');
        fetch(`${end_point}${customUrl}`, options)
            .then(data => {
                return data.json();
            }).then((res) => { setApiResponse(res); }).catch((error) => {setApiResponse({Error: 'Bad Request'})})
    }


    const getFormattedCode = (code) => {
        return code;
    }

    const copyContent = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    }

    const getLanguage = () => {
        return 'javascript'
    }

    return (
        <div className="try-it-api-block">
            <div className="lang-selector-header">
                <Dropdown
                    selectVal={lang}
                    handleChange={handleLangChange}
                    options={Object.keys(textData)}
                    isArrowLight={true}
                />
                <Dropdown
                    selectVal={library}
                    handleChange={handleLibChange}
                    options={Object.values(textData?.[lang])}
                    customCssClass={{ 'dropDownBox': 'custom-dropdown-style' }}
                    isArrowLight={true}
                />
            </div>
            <Editor
                height='30vh'
                theme="vs-dark"
                defaultLanguage={getLanguage(lang)}
                value={getFormattedCode(getTextData(lang, library, apiData, end_point))}
                options={{
                    readOnly: true,
                    minimap: {
                        enabled: false,
                      },
                    automaticLayout:true,
                }}
                onChange={() => { }}
            />
            <div className='try-copy-cont'>
                <div>
                    <img src={CopyText} alt='copy text' onClick={() => copyContent(getFormattedCode(getTextData(lang, library, apiData, end_point)))}></img>
                </div>
                <button
                    className='try-it-btn'
                    onClick={() => makeApiCall(apiData)}
                >
                    Try It!
                </button>
            </div>

            <div className="api-response-box">
                <div className="response-text">RESPONSE</div>

                <div className="api-response-block"> {apiResponse && navigator ?
                    <Editor
                        height="16vh"
                        theme="vs-dark"
                        defaultLanguage='json'
                        defaultValue={JSON.stringify(apiResponse, null, 2)}
                        onChange={() => { }}
                        options={{
                            readOnly: true,
                            minimap: {
                                enabled: false,
                              },
                            automaticLayout:true,
                        }}
                    /> : <div className="dummy-api-response"> Click Try It! to start a request and see the response here!</div>}</div>
            </div>
        </div>
    )
}
