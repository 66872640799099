import React from "react";
import PropTypes from "prop-types";
import "./spinner.css";
const Spinner = ({ className }) => (
  <div className={`loader ${className}`}></div>
);
export default Spinner;

Spinner.propTypes = {
  className: PropTypes.string,
};
