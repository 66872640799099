import React, { useState, useEffect } from "react";
import Spinner from "../Spinner/spinner";
import SearchComponent from "./searchComponent/searchComponent";


const Search = ({ pageContext={} }) => {
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
      setIsLoading(false);
    }, []);
  
    return (
      <>
        {isLoading ? (
          <div className="spinner">
            <Spinner />
          </div>
        ) : (
            <SearchComponent data={pageContext} />
        )}
      </>
    );
  };

export default Search

