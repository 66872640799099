import React, { useEffect, useState } from "react";
import ResponseModal from "./components/responseModal/responseModal";
import '../../css/api-component.css';

export default function ApiBlock({
    apiData,
    setApiJSON
}) {

    const [selectedCode, setSelectedCode] = useState(200)
    const [allCodes, setAllCodes] = useState([])
    const [isResponseModalOpen, setIsResponseModalOpen] = useState(false)

    const handleApiDataChange = (userInput, paramInd) => {
        let tempData = { ...apiData };
        if (tempData.params.length !== 0) {
            tempData.params[paramInd].customUserInput = userInput;
        }
        setApiJSON(tempData);
    }

    const checkIfPresent = (paramsList, val) => {
        const isPresent = paramsList.find((element) => element.in === val)
        return !!isPresent;
    }

    useEffect(()=>{
        if(apiData?.results?.codes){
            const responseData= apiData.results.codes;
            const allCodesObj = {}
            responseData.forEach((resp) => {
                allCodesObj[resp.status] = 1;
            })
            setAllCodes([...Object.keys(allCodesObj)])
        }
    },[apiData])

    return (
        <>
            <div className="api-data-cont" >
                {apiData?.params?.length !== 0 && (checkIfPresent(apiData.params, 'header') || checkIfPresent(apiData.params, 'path')) && <>
                    <br />
                    <div className="header-text">
                        HEADERS
                    </div>
                    <div className='api-param-table'>
                        {apiData?.params?.map((headerData, index) => (headerData.in === 'header' || headerData.in === 'path') && (
                            <div key={index} className="api-param-tab">
                                <div className="api-param-desc">
                                    <div className="api-param-title">{headerData.name} <span className="header-type">{headerData.type}</span> <span className="required-text">{headerData.required ? 'required' : ''}</span></div>
                                    <div>{headerData.desc}</div>
                                </div>
                                <div>
                                    <input type='text' className="header-custom-input" defaultValue={headerData?.default ? headerData.default : ''} onInput={(e) => handleApiDataChange(e.target.value, index)}>
                                    </input>
                                </div>
                            </div>
                        ))}
                    </div>
                </>}
                {apiData?.params?.length !== 0 && checkIfPresent(apiData.params, 'body') && <>
                    <br />
                    <div className="header-text">
                        BODY PARAMS
                    </div>
                    <div className='api-param-table'>
                        {apiData.params.map((headerData, index) => headerData.in === 'body' && (
                            <div key={index} className="api-param-tab">
                                <div className="api-param-desc">
                                    <div className="api-param-title">{headerData.name} <span className="header-type">{headerData.type}</span> <span className="required-text">{headerData.required ? 'required' : ''}</span></div>
                                    <div>{headerData.desc}</div>
                                </div>
                                <div>
                                    <input type='text' className="header-custom-input" defaultValue={headerData?.default ? headerData.default : ''} onInput={(e) => handleApiDataChange(e.target.value, index)}>
                                    </input>
                                </div>
                            </div>
                        ))}
                    </div></>}

                <ResponseModal 
                    selectedCode={selectedCode} 
                    responseData={apiData?.results?.codes} 
                    setSelectedCode={setSelectedCode} 
                    isOpen={isResponseModalOpen} 
                    setIsOpen={setIsResponseModalOpen} 
                    allCodes={allCodes}
                />

                {apiData?.results?.codes?.length !== 0 && <>
                    <br />
                    <div className="header-text">
                        RESPONSES
                    </div>
                    <div className='api-param-table'>
                        {allCodes.map((codeData, index) => (
                            <div key={index} className="api-param-tab" onClick={()=>{
                                setSelectedCode(parseInt(codeData));
                                setIsResponseModalOpen(true);
                            }}>
                                <div className="api-param-desc">
                                    <div className="api-param-title">Code {codeData} </div>
                                    <div>{codeData}
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div></>}
            </div>
        </>
    )
}
