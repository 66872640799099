import { useRef, useMemo, useEffect } from "react";

const debounce = (cb, delay) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => cb(...args), delay);
  };
};

const useDebounce = callback => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const cb = (...args) => {
      ref.current?.(...args);
    };

    return debounce(cb, 500);
  }, []);

  return debouncedCallback;
};

export default useDebounce;
