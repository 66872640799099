import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

// This component is to render CustomLink which is either normal Gatsby Link or if page has cta then <a> tag with onClick which makes it hard reload
const CustomLink = ({
  ctaDetails = [],
  to,
  children,
  overrideGeneralConfig = false,
  onClick = () => {},
  ...rest
}) => {
  const doesContainCta =
    ctaDetails && ctaDetails?.length > 0;
  const url = to || "#";

  return overrideGeneralConfig || doesContainCta ? (
    <a
      onClick={() => {
        onClick && onClick();
        window.location.href = url;
      }}
      {...rest}
    >
      {children}
    </a>
  ) : (
    <Link to={url} onClick={onClick} {...rest} >
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  ctaDetails: PropTypes.object,
  to: PropTypes.string,
  children: PropTypes.object,
  overrideGeneralConfig: PropTypes.bool,
  onClick: PropTypes.func,
};
export default CustomLink;
