import React, { useState, useRef, useEffect } from "react";
import DownArrow from '../../../images/down-arrow-svgrepo-com.svg'
import GreenTick from '../../../images/green-tick.svg';
import './dropdown.css';

export default function DropdownLight({ selectVal, label, options, customCssClass, handleChange }) {
    const menuRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClicks);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClicks);
        };

    }, [isOpen]);

    const handleOutsideClicks = (event) => {
        if (isOpen && menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        };
    };

    return (
        <div>
            <div className='dropdown-btn-cont cs-dropdown-light' ref={menuRef} onClick={() => setIsOpen(!isOpen)}>
                <span className='lang-text-light'>{label}&nbsp;<span><img src={DownArrow} className={`down-arrow-icon ${isOpen?'rotate-icon-180':''}`} alt='down arrow'></img></span></span>
                {isOpen && <div className={`cs-dropdown cs-dropdown-cont`}>
                    {options.map((opt, index) => <div key={index} onClick={() => handleChange(opt.value)} className='cs-dropdownContent cs-dropdownCont-light'>
                        <span>{opt.label}</span>
                        {selectVal === opt.value && <img src={GreenTick} alt='green-tick'></img>}
                    </div>)}
                </div>}
            </div>
        </div>
    )
}
