import React, { useEffect, useRef } from "react";
import './modal.css'

export default function Modal({
    isOpen,
    setIsOpen,
    children
}) {
    const modalEle = useRef(null);
    useEffect(() => {
        // When the user clicks anywhere outside of the modal, close it
        const clickFn = function (event) {
            if (event.target == modalEle.current) {
                setIsOpen(false);
            }
        }
        window.addEventListener('click', (event)=>clickFn(event))
        return window.removeEventListener('click', clickFn)
    }, [])
    return (
        <div id="myModal" ref={modalEle} class={`modal ${isOpen ? 'showModal' : ''}`}>
            <div class="modal-content">
                {/* <span class="close">&times;</span> */}
                {children}
            </div>
        </div>
    )
}
