export const textData = {
    node: [
        'axios',
        'api',
        'node-fetch',
        'http',
        'request'
    ],
    shell: [
        'cURL',
        'HTTPie'
    ],
    javascript: [
        'axios',
        'fetch',
        'jquery',
        'xmlhttprequest'
    ],
    python: [
        'default'
    ]
}

export const getTextData = (lang, library, apiData, end_point) => {

    let headersArr = [];
    let bodyArr = []
    let customUrl = ''
    if (apiData?.params) {
        headersArr = apiData.params.filter((param) => param.in === 'header' && (param.default || param.customUserInput))
        bodyArr = apiData.params.filter((param) => param.in === 'body' && (param.default || param.customUserInput))
        customUrl = apiData.url;
        apiData.params.forEach((param) => {
            if (param.in === 'path' && (param.customUserInput || param.default)) {
                customUrl = customUrl.replaceAll(`{${param.name}}`, `${param.customUserInput || param.default}`)
            }
        })
    }
    const apiFormatData = {
        node: {
axios:`
const axios = require('axios');
const options = {
  method: '${apiData?.method}',
  url: '${end_point}${customUrl}',
  headers: {
        accept: 'text/plain',
        ${headersArr.map((header) => `'${header.name}': '${header.customUserInput || header.default}',\n\t\t\t\t`)}},
${ (apiData?.method==='post' || apiData?.method==='put')?`data: {${bodyArr.map((header) => `\n\t ${header.name} : '${header.customUserInput || header.default}'`)}\n}`:''}\n};
axios
  .request(options)
      .then(function (response) {
      console.log(response.data);
  })
  .catch(function (error) {
    console.error(error);
  });`,
api: `
const sdk = require('api')('@phonepe-docs/v1#3dxznuf1gljiezluv');

            sdk.payApi1( ${headersArr.map((header) => `{ ${header.name} : '${header.customUserInput || header.default}' }, `)} 
            ${bodyArr.map((header) => `{${header.name} : '${header.customUserInput || header.default}'}`)})
              .then(({ data }) => console.log(data))
              .catch(err => console.error(err));`,

'node-fetch': `
const fetch = require('node-fetch');
const url = '${end_point}${customUrl}';
const options = {
    method: '${apiData?.method}',
    headers: {
      accept: 'text/plain',
      ${headersArr.map((header) => `'${header.name}' : '${header.customUserInput || header.default}'\n\t\t\t`)}},
      ${ (apiData?.method==='post' || apiData?.method==='put')?`body: JSON.stringify(${bodyArr.map((header) => `${header.name} : '${header.customUserInput || header.default}'`)})`:''}
    };
    
fetch(url, options)
  .then(res => res.json())
    .then(json => console.log(json))
      .catch(err => console.error('error:' + err));`,

http: `
const http = require('https');
const options = {
  method:  '${apiData?.method}',
  hostname: 'mercury-uat.phonepe.com',
  port: null,
  path:  '${end_point}${apiData?.url}',
  headers: {
    accept: 'text/plain',
    ${headersArr.map((header) => `'${header.name}' : '${header.customUserInput || header.default}'\n\t\t`)}}
};
    
const req = http.request(options, function (res) {
  const chunks = [];
    
  res.on('data', function (chunk) {
        chunks.push(chunk);
  });
    
  res.on('end', function () {
        const body = Buffer.concat(chunks);
        console.log(body.toString());
      });
    });

${ (apiData?.method==='post' || apiData?.method==='put')?`req.write(JSON.stringify({${bodyArr.map((header) => `'${header.name}' : '${header.customUserInput || header.default}'`)}}));`:''}
req.end();`,
            request: `
const request = require('request');
const options = {
    method:  '${apiData?.method}',
    url: '${end_point}${apiData?.url}',
    headers: {
      accept: 'text/plain',
      ${headersArr.map((header) => `\n ${header.name} : '${header.customUserInput || header.default}' `)}
    }
    ${ (apiData?.method==='post' || apiData?.method==='put')?` body: {${bodyArr.map((header) => `${header.name} : '${header.customUserInput || header.default}'`)}},`:''}
    };
    
request(options, function (error, response, body) {
  if (error) throw new Error(error);
    console.log(body);
  });`
        },
        shell: {
            cURL: `curl --request  ${apiData?.method}\  \n
        --url ${end_point}${apiData?.url} \ \n
        --header 'Content-Type: application/json' \ \n
        --header 'X-CALLBACK-URL: https://www.demoMerchant.com/callback' \ \n
        --header 'accept: application/json'\ \n
        ${headersArr.map((header) => `\n --header ${header.name} : '${header.customUserInput || header.default}' `)}
        ${ (apiData?.method==='post' || apiData?.method==='put')?`--data :'{ ${bodyArr.map((header) => `${header.name} : '${header.customUserInput || header.default}'`)}}'`:''}
        `
        },
        javascript: {
            axios: `
import axios from 'axios';
const options = {
  method:  '${apiData?.method}',
  url: '${end_point}${apiData?.url}',
  headers: {
    accept: 'text/plain',
    ${headersArr.map((header) => `\n ${header.name} : '${header.customUserInput || header.default}' `)}
    },
    ${ (apiData?.method==='post' || apiData?.method==='put')?` data: {${bodyArr.map((header) => `${header.name} : '${header.customUserInput || header.default}'`)}}`:''}
    };
        
  axios
    .request(options)
      .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            console.error(error);
        });`,
fetch: `
const options = {
  method:  '${apiData?.method}',
  headers: {
    accept: 'text/plain',
    ${headersArr.map((header) => `\n ${header.name} : '${header.customUserInput || header.default}' `)}
    },
    ${ (apiData?.method==='post' || apiData?.method==='put')?` body: JSON.stringify({${bodyArr.map((header) => `${header.name} : '${header.customUserInput || header.default}'`)}})`:''}
    };
          
fetch('${end_point}${apiData?.url}', options)
  .then(response => response.json())
  .then(response => console.log(response))
  .catch(err => console.error(err));`,
jquery: `
const settings = {
  async: true,
  crossDomain: true,
  url: '${end_point}${apiData?.url}',
  method:  '${apiData?.method}',
  headers: {
    accept: 'text/plain',
    ${headersArr.map((header) => `\n ${header.name} : '${header.customUserInput || header.default} `)}
    },
    processData: false,
    ${ (apiData?.method==='post' || apiData?.method==='put')?`data: '{${bodyArr.map((header) => `${header.name} : ${header.customUserInput || header.default},`)}}'`:''}
    };
          
$.ajax(settings).done(function (response) {
  console.log(response);
});`,
xmlhttprequest: `   
${ (apiData?.method==='post' || apiData?.method==='put')&&`const data = JSON.stringify({
            ${bodyArr.map((header) => `${header.name} : ${header.customUserInput || header.default},`)}`}
          });
          
          const xhr = new XMLHttpRequest();
          xhr.withCredentials = true;
          
          xhr.addEventListener('readystatechange', function () {
            if (this.readyState === this.DONE) {
              console.log(this.responseText);
            }
          });
          
          xhr.open( '${apiData?.method}', '${end_point}${apiData?.url}');
          xhr.setRequestHeader('accept', 'text/plain');
          ${headersArr.map((header) => `\n   xhr.setRequestHeader( '${header.name}' : '${header.customUserInput || header.default}') `)}
          xhr.send(data);`
        },
        python: {
            default: `import requests

        url = "${end_point}${apiData?.url}"
        
        ${ (apiData?.method==='post' || apiData?.method==='put')?`payload = {  ${bodyArr.map((header) => `${header.name} : "${header.customUserInput || header.default}",`)}}`:''}
        headers = {
            "accept": "text/plain",
            ${headersArr.map((header) => `\n ${header.name} : "${header.customUserInput || header.default}" `)}
        }
        
        response = requests.post(url, json=payload, headers=headers)
        
        print(response.text)`
        }
    }
    return apiFormatData?.[lang]?.[library]
}
