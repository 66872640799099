import React, { useState, useEffect } from "react";
import './responseModal.css'

export default function ObjectComp({
    code,
    isOpen,
    name,
    level
}) {
    const [isObjectOpen, setIsObjectOpen] = useState(isOpen)

    useEffect(() => {
        setIsObjectOpen(isOpen)
    }, [isOpen])

    return (
        <>
            {name && <div onClick={() => setIsObjectOpen(!isObjectOpen)}>{name}</div>}
            <div className={`resp-modal ${isObjectOpen ? 'resp-show-modal' : ''} ${level % 2 === 0 ? 'even' : 'odd'}`} >
                {code && Object.keys(code).map((key, index) => (
                    <div key={index} className={`resp-code-block-tile`} >
                        {typeof (code[key]) === 'object' ?
                            <ObjectComp
                                code={code[key]}
                                isOpen={false}
                                name={`${key}  Object`}
                                level={level+1}
                            />
                            :
                            <div>
                                <span className="resp-key-text" >{key}</span> {typeof (code[key])}
                            </div>
                        }
                    </div>
                ))}
            </div>
        </>

    )
}
