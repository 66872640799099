// export const parserOptions = {
//   replace: domNode => {
//     if (
//       domNode &&
//       domNode.name === "a" &&
//       domNode.attribs &&
//       domNode.attribs.href
//     ) {
//       if (domNode.attribs.href[0] === "/") {
//         domNode.attribs.href = process.env.GATSBY_PATH_PREFIX
//           ? process.env.GATSBY_PATH_PREFIX + domNode.attribs.href
//           : domNode.attribs.href
//       }
//     }
//   },
// }

export function trimMonthInDateString(date){
    //expecting date in the format 'month date, year'
    const splittedDate = date.split(' ')
    splittedDate[0] = splittedDate[0].substring(0, 3) // trimming month
    return splittedDate.join(' ')
  }