import React from 'react';
import { getRedirectionLink } from '../../SideNav/utils';

export default function MobileNavigator({
    docType,
    selectedVersionId,
    versionData,
    categroyData,
    normalizedCat,
}) {
    const checkIfChildrenExist = (catgry) => {
        if (categroyData[catgry]?.posts?.nodes?.length) {
            return true;
        }
        if (normalizedCat[catgry]?.length) {
            return normalizedCat[catgry].some((subCat) => categroyData[subCat]?.posts?.nodes?.length > 0)
        }
        return false;
    }

    const getSortedPosts = (postList) => {
        if (postList?.length) {
            const posts = [...postList];
            posts.sort((postA, postB) =>
                postA.postOrder?.order - postB.postOrder?.order
            )
            return posts;
        }
        return [];
    }

    const checkIfCategoryVisible = (catgry) => {
        if (catgry?.isvisible?.visibility) return true;
        return false;
    }

    const isActive = (redirectLink) => {
        const currentLocation = window?.location?.pathname?.replaceAll('/', '');
        const link = redirectLink.replaceAll('/', '');
        return currentLocation === link ? { className: 'active' } : null;
    }

    return  versionData?.[selectedVersionId]?.map((catgry) => categroyData[catgry].description === docType && (
                checkIfChildrenExist(catgry) && checkIfCategoryVisible(categroyData[catgry])
            ) &&
                <>
                    <optgroup key={catgry} label={categroyData[catgry].name}>
                        {categroyData[catgry]?.posts?.nodes?.length !== 0 && getSortedPosts(categroyData[catgry].posts.nodes)?.map((docs, index) => (
                            <option
                                key={index}
                                selected={isActive(getRedirectionLink(docs.uri, categroyData, catgry, selectedVersionId))}
                                value={getRedirectionLink(docs.uri, categroyData, catgry, selectedVersionId)}
                            >
                                {docs.title}
                            </option>
                        ))}
                    </optgroup>
                    {normalizedCat[catgry]?.map((subCat) => (checkIfCategoryVisible(categroyData[subCat]) &&
                        categroyData[subCat]?.posts?.nodes?.length > 0 && (
                            <optgroup key={subCat} label={categroyData[subCat].name}>
                                {getSortedPosts(categroyData[subCat]?.posts?.nodes).map((docs) => (
                                    <option
                                        key={docs.id}
                                        selected={isActive(getRedirectionLink(docs.uri, categroyData, catgry, selectedVersionId))}
                                        value={getRedirectionLink(docs.uri, categroyData, catgry, selectedVersionId)}
                                    >
                                        {docs.title}
                                    </option>
                                ))}
                            </optgroup>
                        )))}
                </>
            )
}