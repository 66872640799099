import React, { useEffect, useState } from "react";
import CategoryComp from "./components/categroy-component";
import "./SideNav.css";

const Category = ({ 
  data, 
  selectedVersionId, 
  selectedCategory, 
  docType, 
  setDocType,
  versionData,
  setVersionData,
  categroyData,
  setCatgoryData,
  normalizedCat,
  setNormalizedCat,
}) => {
  useEffect(() => {
    if (data) {
      let tempData = [...data];
      let catData = {};
      let parentNodes = {};
      let versionNodes = {};
      tempData.forEach((cat) => {
        catData[cat.id] = cat;
        if (!cat.parentId && cat.id === selectedVersionId) {
          if (cat.name !== 'Uncategorized');
          versionNodes[cat.id] = [];
        }
      })
      tempData.forEach((cat) => {
        if (versionNodes[cat.parentId]) {
          versionNodes[cat.parentId].push(cat.id);
          parentNodes[cat.id] = [];
        }
      })
      tempData.forEach((cat) => {
        if (cat.parentId) {
          if (parentNodes[cat.parentId])
            parentNodes[cat.parentId].push(cat.id);
        }
      })
      sortCategories(versionNodes, parentNodes, catData);
    }
  }, [data])


  const sortCategories = (versionNodes, parentNodes, catData) => {
    if (versionNodes) {
      Object.keys((versionNodes)).forEach((version) => {
        let childNodes = [...versionNodes[version]];
        childNodes = childNodes.sort((catA, catB) =>
          catData[catA].categoryOrder?.acfOrder - catData[catB].categoryOrder?.acfOrder
        );
        versionNodes[version] = [...childNodes];
      })
    }
    if (parentNodes) {
      Object.keys((parentNodes)).forEach((node) => {
        let childNodes = [...parentNodes[node]];
        childNodes.sort((catA, catB) => 
          catData[catA].categoryOrder?.acfOrder - catData[catB].categoryOrder?.acfOrder
        );
        parentNodes[node] = childNodes;
      })
    }
    setVersionData(versionNodes);
    setNormalizedCat(parentNodes);
    setCatgoryData(catData);

  }

  useEffect(() => {
    if (categroyData) {
      const docCat = selectedCategory.description || categroyData[selectedCategory.parentId]?.description;
      if (docCat) {
        setDocType(docCat)
      }
    }
  }, [categroyData])

  return (
    <div className="categories" >
      <div className="category-list">
        {Object.keys(versionData).map((version, index) => (
          <div key={index}>
            <li>
              <b>{categroyData[version]?.name}</b>
            </li>
            {versionData?.[selectedVersionId]?.map((catgry) => categroyData[catgry].description === docType && (
              <CategoryComp
                catgry={catgry}
                normalizedCat={normalizedCat}
                categroyData={categroyData}
                selectedCategoryId={selectedCategory.id}
                selectedVersionId={selectedVersionId}
              />
            ))}
          </div>
        ))}

      </div>
    </div>
  )
}

export default Category
