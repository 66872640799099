import React from 'react'
import ApiReferenceIcon from '../../images/api-reference.png'
import DocumentationIcon from '../../images/documentation.png'

export const allDocTypes = () => {
    return [
        {
            label: <div className="option-cont"><img src={DocumentationIcon} alt='documentation'></img>&nbsp; Documentation</div>,
            value: 'guide'
        },
        {
            label: <div className="option-cont"><img src={ApiReferenceIcon} alt='api reference'></img>&nbsp; Api Reference</div>,
            value: 'reference'
        }
    ]
}

export const getSelectedDocType = (docType) => {
    switch (docType) {
        case 'guide': return <div>Documentation</div>;
        case 'reference': return <div>Api Reference</div>;
        default: return null;
    }
}
