import React, { useState } from "react";
import { allDocTypes, getSelectedDocType } from "./utils";
import DropdownLight from "../commonComponents/dropDown/dropdown-light";
import SearchIcon from '../../images/search.svg';
import MobileNavigator from "./mobileNavigator/mobileNavigator";
import './searchHeader.css'

export default function SearchHeader({
    docType,
    setDocType,
    setOpenSearch,
    versionData,
    categroyData,
    normalizedCat,
    selectedVersionId
}) {
    const handleChange = (value) => {
        if (value) {
            window.location = value;
        }
    }
    return (
        <div className="searchHeader">
            <div className="options-cont">
                <div className="doc-type-text">
                    <DropdownLight
                        selectVal={docType}
                        label={getSelectedDocType(docType)}
                        options={allDocTypes()}
                        handleChange={(val) => setDocType(val)}
                    />
                </div>
                <div className="searchBtn" onClick={() => setOpenSearch(true)}>
                    <img src={SearchIcon} alt="search" width='20px'></img>
                    Search
                </div>
            </div>
            <div className="category-select-mobile" >
                {versionData?.[selectedVersionId] && <select name="categories" id="mobile-navigator" onChange={(event) => handleChange(event.target.value)} >
                    <optgroup label="Documentation">
                    </optgroup>
                    <MobileNavigator
                        docType='guide'
                        versionData={versionData}
                        categroyData={categroyData}
                        normalizedCat={normalizedCat}
                        selectedVersionId={selectedVersionId}
                    />
                    <optgroup label="Reference">
                    </optgroup>
                    <MobileNavigator
                        docType='reference'
                        versionData={versionData}
                        categroyData={categroyData}
                        normalizedCat={normalizedCat}
                        selectedVersionId={selectedVersionId}
                    />
                </select>}
            </div>
        </div>
    )
}
