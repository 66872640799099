import React, { useState, useEffect } from "react";
import { getRedirectionLink } from "../utils";
import downArrow from '../../../images/down-arrow-svgrepo-com.svg';
import document from '../../../images/document.svg';
import { Link } from "gatsby";

export default function CategoryComp({ catgry, normalizedCat, categroyData, selectedCategoryId, selectedVersionId }) {
    const [isContentListOpen, setContentListOpen] = useState(selectedCategoryId);

    const checkIfChildrenExist = () => {
        if (categroyData[catgry]?.posts?.nodes?.length) {
            return true;
        }
        if (normalizedCat[catgry]?.length) {
            let postExists = false;
            normalizedCat[catgry].forEach((subCat) => {
                if (categroyData[subCat]?.posts?.nodes?.length > 0) {
                    postExists = true;
                }
            })
            return postExists;
        }
        return false;
    }

    const getSortedPosts = (postList) => {
        if (postList && postList.length !== 0) {
            const posts = [...postList];
            posts.sort((postA, postB) =>
                postA.postOrder?.order - postB.postOrder?.order
            )
            return posts;
        }
        return [];
    }

    const isActive = (props, redirectLink) => {
        const currentLocation = props?.pathname?.replaceAll('/', '');
        const link = redirectLink.replaceAll('/', '');
        return currentLocation === link ? { className: 'active' } : null;
    }

    useEffect(() => {
        const selectedDocEle = window?.document?.getElementsByClassName('active')?.[0];
        if (selectedDocEle) {
            selectedDocEle.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'nearest' });
        }
    }, [])

    const checkIfCategoryVisible = (catgry) => {
        return catgry?.isvisible?.visibility
    }

    if (checkIfChildrenExist() && checkIfCategoryVisible(categroyData[catgry]))
        return (
            <li key={catgry}>
                <div
                    itemProp="url"
                    className="category-links category-title"
                >
                    {categroyData[catgry].name}
                </div>
                {categroyData[catgry]?.posts?.nodes?.length !== 0 && getSortedPosts(categroyData[catgry].posts.nodes)?.map((docs, index) => (
                    <li key={index}>
                        <Link
                            to={getRedirectionLink(docs.uri, categroyData, catgry, selectedVersionId)}
                            itemProp="url"
                            className="category-links"
                            activeClassName="active"
                            getProps={({ location }) => isActive(location, getRedirectionLink(docs.uri, categroyData, catgry, selectedVersionId))}
                        >
                            <span className="category-name-cont docs-title no-left-padding">
                                <img src={document} alt='document' height='12px'></img>
                                &nbsp;{docs.title}
                            </span>
                        </Link>
                    </li>
                ))}
                {normalizedCat[catgry].map((subCat) => (checkIfCategoryVisible(categroyData[subCat]) &&
                    categroyData[subCat]?.posts?.nodes?.length > 0 && <li key={subCat}>
                        <div
                            onClick={() => setContentListOpen(isContentListOpen === subCat ? false : subCat)}
                            className="category-links sub-cat-title"
                        >
                            <span className="category-name-cont">
                                <img src={downArrow} className={`down-arrow-icon ${isContentListOpen === subCat ? 'rotate-icon-0' : 'rotate-icon-270'}`} alt='down-arrow'></img
                                >&nbsp;
                                {categroyData[subCat].name}
                            </span>
                        </div>
                        {isContentListOpen === subCat && getSortedPosts(categroyData[subCat]?.posts?.nodes).map((docs) => (
                            <li key={docs.id}>
                                <Link
                                    to={getRedirectionLink(docs.uri, categroyData, subCat, selectedVersionId)}
                                    itemProp="url"
                                    className="category-links"
                                    activeClassName="active"
                                    getProps={({ location }) => isActive(location, getRedirectionLink(docs.uri, categroyData, catgry, selectedVersionId))}
                                >
                                    <span className="category-name-cont docs-title"><img src={document} alt='document' height='12px'></img>  &nbsp;{docs.title}</span>
                                </Link>
                            </li>
                        ))}
                    </li>
                ))}
            </li>
        )
    return <></>
}
