import React, { useEffect, useState } from "react";
import Modal from "../../../commonComponents/modal/modal";
import ObjectComp from "./objectComp";
import CloseIcon from "../../../../images/close-icon.png";
import './responseModal.css'

export default function ResponseModal({
    isOpen,
    setIsOpen,
    selectedCode,
    setSelectedCode,
    responseData,
    allCodes
}) {
    const [codeData, setAllCodeData] = useState([])
    const [openIndex, setOpenIndex] = useState(0);

    useEffect(() => {
        if (responseData) {
            const codeArr = [];
            const allCodesArr = {}
            responseData.forEach((resp) => {
                allCodesArr[resp.status] = 1;
                if (resp.status === selectedCode) {
                    let respJSON = {};
                    try{
                        respJSON = JSON.parse(resp.code)
                    }
                    catch(error){
                        console.log(error)
                    }
                    const codeObj = {
                        name: resp.name,
                        lang: resp.lang,
                        code: respJSON
                    }
                    codeArr.push(codeObj);
                }
            })
            setAllCodeData(codeArr)
        }
    }, [selectedCode])

    const handleChange = (e) => {
        setSelectedCode(parseInt(e.target.value))
    }

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <div className="response-body" >
                <div className="resp-header">
                    <div className="header-text" >Response <select onChange={handleChange} className="options-cont"  >
                        {allCodes.map((code) => <option value={code} selected={selectedCode == code} >{code}</option>)}
                    </select>
                    </div>
                    <div className="close-icon" ><img src={CloseIcon} alt='close' width='30px' onClick={()=>setIsOpen(false)} ></img></div>
                </div>
                <div className="resp-data" >
                    <div className="resp-body-text">RESPONSE BODY</div>
                    <div className="resp-code-body" >
                        {codeData.map((data, index) => <div className="resp-code-tab" key={index}  >
                           {data.name&& <div className="resp-code-name" onClick={() => setOpenIndex(index)} ><div> {data.name}</div> <div>+</div></div>}
                            {index === openIndex&&<div className="resp-code-block">
                                <ObjectComp code={data.code} isOpen={index === openIndex} level={1} />
                            </div>}
                        </div>)}
                    </div>
                </div>
            </div>
        </Modal>
    )
}
